<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top head-danger">
    <div class="container-fluid">
      <div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" href="#">
          <img src="@/assets/logo.png" alt="" width="30" height="24" />
          <span>¿Quién es ese Pokémon?</span>
        </a>
      </div>

      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Disabled</a
            >
          </li>
        </ul> -->
      </div>     
    </div>
  </nav>
</template>
<script>
export default {
  name: "headerTemplate",
  data() {
    return {
      play: false,
    };
  },
  methods: {
    playMusic() {

    },
  },
};
</script>
<style scoped>
.head-danger {
  background-color: #ef5350;
}
span {
  color: white;
  font-weight: bold;
}
</style>