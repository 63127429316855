<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div v-if="pokemon" class="m-2 p-5">
          <PokemonPicture :pokemonId="pokemon.id" :showPokemon="showPokemon" />
          <PokemonOptions :pokemons="pokemonArr" @selection="checkAnswer" />

          <template v-if="showAnswer">
            <h2 class="fade-in">{{ msg }}</h2>
            <button class="btn btn-primary btn-sm" @click="newGame">
              Nuevo Juego
            </button>
          </template>
        </div>
        <div v-else class="d-flex align-items-center">
          <strong>Loading...</strong>
          <div
            class="spinner-border ms-auto"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
      </div>
      <div class="col-lg-6">
        <GottaCatch :catchs="pokeCorrect" />
      </div>
    </div>
    <ModalInfo />
  </div>
</template>
<script>
import PokemonOptions from "@/components/PokemonOptions";
import PokemonPicture from "@/components/PokemonPicture";

import getPokemonsOptions from "@/helpers/getPokemonOptions";
import GottaCatch from "@/components/GottaCatch.vue";
import ModalInfo from '@/components/ModalInfo.vue';


export default {
  components: {
    PokemonPicture,
    PokemonOptions,
    GottaCatch,
    ModalInfo
  },
  data() {
    return {
      pokemonArr: [],
      pokeCorrect: [],
      pokemon: null,
      showPokemon: false,
      showAnswer: false,
      msg: "",
    };
  },
  methods: {
    async mixPokemonArray() {
      this.pokemonArr = await getPokemonsOptions();
      const rndInt = Math.floor(Math.random() * 4);
      this.pokemon = this.pokemonArr[rndInt];
    },
    checkAnswer(pokeId) {
      this.showPokemon = true;
      this.showAnswer = true;

      if (this.pokemon.id === pokeId) {
        this.msg = `Correcto, ${this.pokemon.name}`;
        let poke = {url: `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/${pokeId}.svg`, name: this.pokemon.name, id: this.pokemon.id };
        this.pokeCorrect.push(poke);
      } else this.msg = `Oops!!, el pokémon era ${this.pokemon.name}`;
    },
    newGame() {
      this.showPokemon = false;
      this.showAnswer = false;
      this.pokemonArr = null;
      this.pokemon = null;
      this.mixPokemonArray();
    },
  },
  mounted() {
    this.mixPokemonArray();
  },
};
</script>
<style scoped>
</style>