<template>
    <div v-if="pokes.length > 0">
        <h5 class="fw-bold rounded-pill bg-primary text-white m-2 p-3">Has adivinado {{pokes.length}} pokémon</h5>
        <template v-for="poke in pokes" :key="poke.id">       
            <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"> -->
                <img :src="poke.url" :alt="poke.name" :width="viewWidth" >            
            <!-- </a>    -->
        </template>
    </div>
</template>
<script>
export default {
    props: ['catchs'],
    data(){
        return{
            pokes: this.catchs
        }
    },
    computed:{
        viewWidth(){
            if(this.pokes.length < 10) return 100;
            else return 50;
        }
    }
}
</script>
<style>
    
</style>