<template>
  <footer class="fixed-bottom d-flex justify-content-between">
      <div>
          <a href="https://pokeapi.co/" target="_blank" rel="noopener noreferrer">
            <img src="@/images/pokeapi.png" alt="pokeapi">
          </a>
      </div>
    <div class="aga">
        &copy; <a href="https://adangarnica.com">Adan Garnica Albor - {{new Date().getFullYear()}}</a>        
    </div>    
    <div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterTemplate",
};
</script>
<style scoped>
footer {
  text-align: center;
  background-color: #EF5350;
  width: 100%;
  height: 60px;
  color: white;
}
.aga {    
    font-weight: bold;    
    cursor: pointer;
}
a {
    text-align: center;
    text-decoration: none;
    color: white;
}
img {
    height: 40px;
    margin-top: 5px;
    margin-left: 5px;
}
</style>