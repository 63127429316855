<template>
  <div class="container-fluid">
    <header>
      <HeaderTemplate />
    </header>
    <main class="clearfix container">
       <div class="align-self-end">
        <audio controls>
          <source src="@/assets/pokemon-atrapalos-ya_.mp3" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <pokemon-page></pokemon-page>      
    </main>
    <FooterTemplate />
  </div>
</template>

<script>
import PokemonPage from "./pages/PokemonPage.vue";
import FooterTemplate from "./components/footer.vue";
import HeaderTemplate from "./components/header.vue";

export default {
  name: "App",
  components: {
    HeaderTemplate,
    PokemonPage,
    FooterTemplate
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
